<div class="tw-pt-8">
  <div rvSpinner="schedule-loader" [rvShowSpinner]="scheduleService.loadingSchedule">

  <div>
    <form #scheduleForm="ngForm" id="forms.scheduleForm" role="form" name="forms.scheduleForm" (ngSubmit)="save()" autocomplete="on" novalidate>

      <schedule-fields></schedule-fields>

      <div class="button-row text-right mt-5">
        <p class="visible-sm visible-xs text-right"><last-modified [changeDate]="scheduleService.schedule.changeDate" [changedBy]="scheduleService.schedule.changedBy"></last-modified></p>
        <span class="hidden-sm hidden-xs"><last-modified [changeDate]="scheduleService.schedule.changeDate" [changedBy]="scheduleService.schedule.changedBy"></last-modified></span>
        <!-- Indicates cancel or non-destructive action -->
        <button id="cancelButton" type="button" uiSref="apps.schedules.list" class="btn btn-default btn-toolbar">
          Cancel
        </button>
        <!-- Indicates a successful or positive action -->
        <button id="saveButton" type="submit" class="btn btn-primary btn-toolbar" ng-disabled="scheduleForm.invalid || scheduleService.savingSchedule" *requireRole="'cp ap'">
          {{ scheduleService.savingSchedule ? 'Saving' : 'Save'}}
        </button>
      </div>
    </form><!-- scheduleForm -->
  </div><!--container-->
</div>
