<div class="tw-pt-8">
  <div rvSpinner="schedule-loader" [rvShowSpinner]="scheduleService.loadingSchedule">

    <form #scheduleForm="ngForm" id="forms.scheduleForm" role="form" name="forms.scheduleForm" (ngSubmit)="save()" autocomplete="on" novalidate>

      <schedule-fields></schedule-fields>

      <div class="button-row text-right mt-5">
        <!-- Indicates cancel or non-destructive action -->
        <button id="cancelButton" type="button" uiSref="apps.schedules.list" class="btn btn-default btn-toolbar">
          Cancel
        </button>
        <!-- Indicates a successful or positive action -->
        <button id="saveButton" type="submit" class="btn btn-primary btn-toolbar" form="forms.scheduleForm" ng-disabled="scheduleForm.invalid || scheduleService.savingSchedule || scheduleService.hasInsecureUrls()" *requireRole="'cp'">
          {{ scheduleService.savingSchedule ? 'Saving' : 'Save'}}
        </button>
      </div><!--button-row-->

    </form><!-- scheduleForm -->
  </div><!--container-->
</div>
