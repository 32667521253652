<div class="company-settings tw-pt-8" rvSpinner="company-settings" [rvShowSpinner]="loading || companyService.loading">
  <form #companyForm="ngForm" id="forms.companyForm" role="form" name="forms.companyForm" (submit)="save()" autocomplete="on" novalidate>
    <div id="errorBox" class="alert alert-danger" role="alert" [shown]="formError">
      <strong>{{formError}}</strong> {{apiError}}
      @if (isAddressError) {
        <suggest-general-delivery [addressObject]="company"></suggest-general-delivery>
      }
    </div>
    <div class="pb-4">
      <div class="flex-row">
        <stretchy-input name="companyNameInput" ngDefaultControl class="mb-0" [(ngModel)]="company.name"></stretchy-input>
        <button type="button" id="deleteButton" aria-label="Delete company" *requireRole="'ua sa'" (click)="deleteCompany()" class="btn-icon pl-0">
          <streamline-icon ariaLabel="Delete company" class="u_margin-left streamline-component-icon" name="trash" width="16" height="16"></streamline-icon>
        </button>
      </div>
    </div>
    <company-fields
      [company]="company"
      showManagedSections="true"
      (resetAuthKeyEvent)="resetAuthKey()"
      (resetClaimIdEvent)="resetClaimId()">
    </company-fields>
    <div class="button-row text-right mt-5">
      <p class="visible-sm visible-xs text-right"><last-modified [changeDate]="company.changeDate" [changedBy]="company.changedBy"></last-modified></p>
      <span class="hidden-sm hidden-xs u_margin-right"><last-modified [changeDate]="company.changeDate" [changedBy]="company.changedBy"></last-modified></span>
      <!-- Indicates cancel or non-destructive action -->
      <button id="cancelButton" type="button" (click)="cancel()" class="btn btn-default btn-toolbar mr-2">
        Cancel
      </button>
      <a href="#" *requireRole="'sa'" (click)="exportCompany()" class="btn btn-default btn-toolbar rise-admin-only pull-left">Export</a>
      <!-- Indicates a successful or positive action -->
      <button id="saveButton" form="forms.companyForm" type="submit" class="btn btn-primary btn-toolbar" [disabled]="companyForm.invalid" *requireRole="'ua sa'">
        Save
      </button>
    </div>
  </form><!-- companyDetails -->
</div>
