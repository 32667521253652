<div class="tw-pt-8" rvSpinner="update-subscription-loader" [rvShowSpinner]="updateSubscriptionService.loading || subscriptionService.loading">
  <div [shown]="updateSubscriptionService.apiError" class="madero-style alert alert-danger text-center u_margin-md-top" role="alert">
    <strong>{{updateSubscriptionService.apiError}}</strong>
    <br/>If you need help please email us at support&#64;risevision.com.
  </div>
  <div id="purchase-licenses-panel" class="row">
    <div class="col-md-8 col-md-offset-2" [hidden]="updateSubscriptionService.purchases[0]?.completed">
      <form #purchaseLicensesForm="ngForm" id="forms.purchaseLicensesForm" role="form" name="forms.purchaseLicensesForm" novalidate (ngSubmit)="completePayment()">
        <h4 class="u_margin-md-bottom">Subscription Details</h4>
        @if (purchaseAction === 'remove' || billingUtils.hasPendingChange(subscriptionService.getItemSubscription())) {
          <pending-change-alert [isUpdate]="true"></pending-change-alert>
        }
        <div class="border-container p-4">
          @if (updateSubscriptionService.allowMultiple) {
            <h5>Display License Subscription</h5>
          }
          @if (purchaseAction === 'add') {
            <div class="left-right-aligner" [ngClass]="{'has-error': licensesToAdd.invalid && updateSubscriptionService.displayLicensePurchase.licensesToAdd !== undefined }">
              <label for="licensesToAdd" class="control-label">Number of display licenses you want to add:</label>
              <input #licensesToAdd="ngModel" class="display-count-input text-md mt-0 pull-right" type="number" name="licensesToAdd" [(ngModel)]="updateSubscriptionService.displayLicensePurchase.licensesToAdd" [min]="updateSubscriptionService.allowMultiple ? 0 : 1" max="10000" ng-pattern="/^[0-9]+$/" required (change)="detectChanges()" (debounceEvent)="getEstimate()" autofocus />
            </div>
          }
          @else if (purchaseAction === 'remove') {
            <div class="left-right-aligner" [ngClass]="{'has-error': licensesToRemove.invalid }">
              <label for="licensesToRemove" class="control-label">Number of display licenses you want to remove:</label>
              <input #licensesToRemove="ngModel" class="display-count-input text-md mt-0 pull-right" type="number" name="licensesToRemove" [(ngModel)]="updateSubscriptionService.displayLicensePurchase.licensesToRemove" min="1" max="{{updateSubscriptionService.getCurrentDisplayCount()-1}}" ng-pattern="/^[0-9]+$/" required (change)="detectChanges()" (debounceEvent)="getEstimate()" autofocus />
            </div>
          }
          <div class="border-bottom mb-4" [shown]="purchaseAction === 'unlimited'">
            <p class="font-weight-bold">
              Upgrade to an Unlimited License subscription
            </p>
            <p class="left-right-aligner">
              <span class="font-weight-bold">Total number of display licenses for this subscription:</span>
              <span>Unlimited</span>
            </p>
            <p class="mb-4">
              <span class="font-weight-bold">Note:</span> An Unlimited License is a site license per school building. If purchasing for a school district or network of schools, you need to purchase a separate license for each school. If you have any questions, our <a href="https://www.risevision.com/contact-us?form_selected=sales&content_hide=true" target="_blank">Sales Team</a> is happy to help!
            </p>
          </div>
          <div class="border-bottom py-4 mb-4" [shown]="purchaseAction !== 'annual' && purchaseAction !== 'unlimited'">
            <p class="left-right-aligner mb-0">
              <span class="font-weight-bold">Total number of display licenses for this subscription:</span>
              <span>
                {{updateSubscriptionService.getTotalDisplayCount()}}
              </span>
            </p>
            @if (updateSubscriptionService.getChangeInLicenses(updateSubscriptionService.displayLicensePurchase) !== 0) {
              <p class="mb-0" [shown]="updateSubscriptionService.displayLicensePurchase.currentPricePerDisplay && updateSubscriptionService.displayLicensePurchase.currentPricePerDisplay === updateSubscriptionService.displayLicensePurchase.newPricePerDisplay">
                {{updateSubscriptionService.displayLicensePurchase.currentPricePerDisplay | currency }} per display license, per {{updateSubscriptionService.displayLicensePurchase.isYearly ? 'year' : 'month'}}.</p>
              <div class="madero-style alert alert-success price-update mt-3 mb-0" [shown]="updateSubscriptionService.displayLicensePurchase.currentPricePerDisplay > updateSubscriptionService.displayLicensePurchase.newPricePerDisplay">
                <p class="mb-0"><strong>Cost will decrease to {{updateSubscriptionService.displayLicensePurchase.newPricePerDisplay | currency }} per display license, per {{updateSubscriptionService.displayLicensePurchase.isYearly ? 'year' : 'month'}}.</strong></p>
              </div>
              <div class="madero-style alert alert-danger price-update mt-3 mb-0" [shown]="updateSubscriptionService.displayLicensePurchase.currentPricePerDisplay < updateSubscriptionService.displayLicensePurchase.newPricePerDisplay">
                <p class="mb-0"><strong>Cost will increase to {{updateSubscriptionService.displayLicensePurchase.newPricePerDisplay | currency }} per display license, per {{updateSubscriptionService.displayLicensePurchase.isYearly ? 'year' : 'month'}}.</strong></p>
              </div>
            }
          </div>
          <div class="stack-sm-sideways-panels pt-0 pb-4 mb-4" [shown]="purchaseAction === 'annual'">
            <div class="mb-2 mb-md-0">
              <span class="font-weight-bold">Choose payment frequency:</span><br />
              Save 10% by paying annually!
            </div>
            <div class="btn-group btn-group-justified billing-period-select ml-auto" (click)="billingPeriod = (billingPeriod === 'monthly' ? 'annually' : 'monthly')">
              <button type="button" class="btn"
                [ngClass]="{'btn-toggle-blue-off' : billingPeriod !== 'monthly', 'btn-toggle-blue-on' : billingPeriod === 'monthly'}">
                Pay Monthly
                <streamline-icon ariaLabel="Monthly payment selected" name="checkmark" [shown]="billingPeriod === 'monthly'"></streamline-icon>
              </button>
              <button type="button" class="btn"
                [ngClass]="{'btn-toggle-blue-off' : billingPeriod !== 'annually', 'btn-toggle-blue-on' : billingPeriod === 'annually'}">
                Pay Annually
                <streamline-icon ariaLabel="Annual payment selected" name="checkmark" [shown]="billingPeriod === 'annually'"></streamline-icon>
              </button>
            </div>
          </div>
          @if (purchaseAction === 'add' && updateSubscriptionService.allowMultiple) {
            <h5>Unlimited License Subscription</h5>
            <div class="left-right-aligner pt-3" [ngClass]="{'has-error': unlimitedLicensesToAdd.invalid && updateSubscriptionService.unlimitedLicensePurchase.licensesToAdd !== undefined }">
              <label for="unlimitedLicensesToAdd" class="control-label">Number of unlimited licenses you want to add:</label>
              <input #unlimitedLicensesToAdd="ngModel" class="display-count-input text-md mt-0 pull-right" type="number" name="unlimitedLicensesToAdd" [(ngModel)]="updateSubscriptionService.unlimitedLicensePurchase.licensesToAdd" [min]="updateSubscriptionService.allowMultiple ? 0 : 1" max="10000" ng-pattern="/^[0-9]+$/" required (change)="detectChanges()" (debounceEvent)="getEstimate()" />
            </div>
            <div class="border-bottom py-4 mb-4">
              <p class="left-right-aligner mb-0">
                <span class="font-weight-bold">Total number of unlimited licenses for this subscription:</span>
                <span>
                  {{updateSubscriptionService.getUnlimitedCount()}}
                </span>
              </p>
              <p class="mb-0" [shown]="updateSubscriptionService.unlimitedLicensePurchase.currentPricePerDisplay">{{updateSubscriptionService.unlimitedLicensePurchase.currentPricePerDisplay | currency }} per year (unlimited displays).</p>
            </div>
          }
          <p class="mb-0" [shown]="!addCoupon && !couponCode && purchaseAction !== 'remove'">
            &nbsp;
            <span class="pull-right">
              <a aria-label="Add Coupon Code" class="madero-link u_clickable" (click)="addCoupon = true" tabindex="1">Add A Coupon Code</a>
            </span>
          </p>
          <div class="row" [shown]="addCoupon">
            <div class="col-md-12">
              <div class="form-group mb-0">
                <label for="coupon-code" class="control-label">Coupon Code:</label>
                <span class="pull-right">
                  <a aria-label="Cancel Coupon Code" class="madero-link u_clickable" (click)="clearCouponCode()" tabindex="1">Cancel</a>
                </span>
                <div class="flex-row">
                  <input id="coupon-code" aria-required="false" type="text" class="form-control mr-3" name="couponCode" [(ngModel)]="couponCode" (keyup.Enter)="applyCouponCode()">
                  <button id="apply-coupon-code" type="button" aria-label="Apply Coupon Code" class="btn btn-default" (click)="applyCouponCode()">Apply</button>
                </div>
              </div>
            </div>
          </div>
          @for (estimate of updateSubscriptionService.estimates; track estimate) {
            @if (updateSubscriptionService.estimates.length > 1) {
              <h4 class="pt-4 mb-0">
                {{ updateSubscriptionService.getPlanType(estimate) === 'display' ?
                    'Display Licenses' : 'Unlimited Licenses' }}
              </h4>
            }
            @if (purchaseAction !== 'remove' && estimate.invoice_estimate?.discounts?.length > 0) {
              <div class="pt-4">
                <p class="coupon-row mb-0">
                  <span>Subtotal</span>
                  <span class="pull-right">{{estimate.invoice_estimate.sub_total/100 | currency }}</span>
                </p>
                @for (coupon of estimate.invoice_estimate?.discounts; track coupon) {
                  <p class="coupon-row mb-0">
                    <span>{{coupon.description}}</span>
                    <span class="pull-right">-{{coupon.amount/100 | currency }}</span>
                  </p>
                }
              </div>
            }
            <div [ngClass]="{ 'pt-4': purchaseAction === 'remove' || !(estimate.invoice_estimate?.discounts?.length > 0) }">
              @if (updateSubscriptionService.getEstimateCredit(estimate)) {
                <p id="prorated-credit-row" class="left-right-aligner mb-4">
                  <span class="font-weight-bold">Prorated credit added to your account:</span>
                  <span>
                    <span class="u_margin-right text-subtle">{{updateSubscriptionService.getCreditCurrency(estimate)}}</span>
                    <span class="purchase-total">{{updateSubscriptionService.getEstimateCredit(estimate) | currency }}</span>
                  </span>
                </p>
              }
              @if (updateSubscriptionService.getInvoiceTotal(estimate)) {
                <p id="prorated-amount-row" class="left-right-aligner mb-4">
                  <span class="font-weight-bold">
                    {{ estimate.subscription_estimate?.id ? 'Prorated amount, due now:' : 'Amount due now:' }}
                  </span>
                  <span>
                    <span class="u_margin-right text-subtle">{{updateSubscriptionService.getInvoiceCurrency(estimate)}}</span>
                    <span class="purchase-total">{{updateSubscriptionService.getInvoiceTotal(estimate) | currency }}</span>
                  </span>
                </p>
              }
              @if (!estimate.subscription_estimate?.id && estimate.invoice_estimate) {
                <p id="renewal-invoice-row" class="left-right-aligner mb-0">
                  <span class="font-weight-bold">Next renewal on {{updateSubscriptionService.getNextRenewalTime(estimate) | date:'d-MMM-yyyy'}}:</span>
                  <span>
                    <span class="u_margin-right text-subtle">{{estimate.invoice_estimate.currency_code}}</span>
                    <span class="purchase-total">{{estimate.invoice_estimate.total/100 | currency }}</span>
                  </span>
                </p>
              }
              @if (updateSubscriptionService.getNextInvoiceTotal(estimate)) {
                <p id="next-invoice-row" class="left-right-aligner mb-0">
                  <span class="font-weight-bold">Next invoice on {{updateSubscriptionService.getNextBillingTime(estimate) | date:'d-MMM-yyyy'}}:</span>
                  <span>
                    <span class="u_margin-right text-subtle">{{updateSubscriptionService.getNextInvoiceCurrency(estimate)}}</span>
                    <span class="purchase-total">{{updateSubscriptionService.getNextInvoiceTotal(estimate) | currency }}</span>
                  </span>
                </p>
              }
            </div>
          }
        </div>
        <div class="row u_margin-md-top mb-5">
          <div class="col-md-4 mb-2">
            <button id="backButton" type="button" aria-label="Go back" class="btn btn-default btn-hg btn-block" (click)="close()">Cancel</button>
          </div>
          <div class="col-md-8">
            @if (updateSubscriptionService.getTotalDue() > 0) {
              <button id="payButton" type="submit" class="btn btn-primary btn-hg btn-block" tabindex="1" aria-label="Complete Payment" [disabled]="purchaseLicensesForm.invalid || billingPeriod === 'monthly'">
                <span id="invoiceLabel">Pay {{updateSubscriptionService.getTotalDue() | currency }} Now</span>
              </button>
            }
            @else {
              <button id="updateButton" type="submit" class="btn btn-primary btn-hg btn-block" tabindex="1" aria-label="Update Subscription" [disabled]="purchaseLicensesForm.invalid || billingPeriod === 'monthly'">
                <span id="invoiceLabel">Update Subscription</span>
              </button>
            }
          </div>
        </div>
      </form>
    </div>
  </div>
  <update-subscription-success [purchaseAction]="purchaseAction" (closeEvent)="close()" [shown]="updateSubscriptionService.purchases[0]?.completed"></update-subscription-success>
  <div class="mt-5 border-top">
    <purchase-footer></purchase-footer>
  </div>
</div>